<script>
import axios from 'axios';
import CryptoJS from 'crypto-js';

export default {

  data() {
    return {
      url: process.env.VUE_APP_URL,
      searchText: '',
      filteredItems: []
    };
  },
  methods: {

    toggleMenu() {
      let element = document.getElementById("topnav-menu-content");
      element.classList.toggle("show");
    },
    search() {

      const searchTextLower = this.searchText.toLowerCase();
      const allItems = [...this.stories, ...this.clients, ...this.journals];
      allItems.sort((a, b) => {
        let includesSearchTextA = false;
        let includesSearchTextB = false;

        if (a.storyTitle) {
        includesSearchTextA = a.storyTitle.toLowerCase().startsWith(searchTextLower);
    } else if (a.firstName || a.lastName) {
        const nameA = (a.firstName + ' ' + a.lastName).toLowerCase();
        includesSearchTextA = nameA.startsWith(searchTextLower);
    } else if (a.jrnlName) {
        includesSearchTextA = a.jrnlName.toLowerCase().startsWith(searchTextLower);
    } else if (a.clientName) {
        includesSearchTextA = a.clientName.toLowerCase().startsWith(searchTextLower);
    }

    // Check if search text is included in item B
    if (b.storyTitle) {
        includesSearchTextB = b.storyTitle.toLowerCase().startsWith(searchTextLower);
    } else if (b.firstName || b.lastName) {
        const nameB = (b.firstName + ' ' + b.lastName).toLowerCase();
        includesSearchTextB = nameB.startsWith(searchTextLower);
    } else if (b.jrnlName) {
        includesSearchTextB = b.jrnlName.toLowerCase().startsWith(searchTextLower);
    } else if (b.clientName) {
        includesSearchTextB = b.clientName.toLowerCase().startsWith(searchTextLower);
    }

    // Items with search text at the beginning come first
    if (includesSearchTextA && !includesSearchTextB) return -1;
    if (!includesSearchTextA && includesSearchTextB) return 1;

    // Sort alphabetically if search text not found at the beginning or both have search text at the beginning
    if (a.storyTitle && b.storyTitle) {
        return a.storyTitle.localeCompare(b.storyTitle);
    } else if (a.firstName || a.lastName && b.firstName || b.lastName) {
        const nameA = (a.firstName + ' ' + a.lastName).toLowerCase();
        const nameB = (b.firstName + ' ' + b.lastName).toLowerCase();
        return nameA.localeCompare(nameB);
    } else if (a.jrnlName && b.jrnlName) {
        return a.jrnlName.localeCompare(b.jrnlName);
    } else if (a.clientName && b.clientName) {
        return a.clientName.localeCompare(b.clientName);
    }

        return 0;
      });
      const filteredItems = allItems.filter(item => {
        if (item.storyTitle && item.storyTitle.toLowerCase().includes(searchTextLower)) {
          return true;
        }
        if (item.firstName || item.lastName) {
          const name = (item.firstName + ' ' + item.lastName).toLowerCase();
          if (name.includes(searchTextLower)) {
            return true;
          }
        }
        if (item.clientName?.toLowerCase().includes(searchTextLower)) {
          return true;
        }
        if (item.jrnlName && item.jrnlName.toLowerCase().includes(searchTextLower)) {
          return true;
        }
        return false;
      });

      this.filteredItems = filteredItems;

      // ==================


    },


    logout() {
      axios.post(this.url + 'api/logout').then(() => {
        localStorage.removeItem('user');
        localStorage.removeItem('logged');
        localStorage.removeItem('authRequired1');
        localStorage.removeItem('email');
        localStorage.removeItem('authenticatedUserId');
        localStorage.removeItem('customUser');
        document.cookie = `${'email'}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        document.cookie = `${'password'}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        const userType = String(atob(decodeURIComponent(JSON.parse(localStorage.getItem('userType1')))));
        if (userType == 'admin') {
          localStorage.removeItem('userType1');
          this.$router.push('/login');
        } else {
          localStorage.removeItem('userType1');
          this.$router.push('/client-login');
        }
      }).catch(error => {
        console.log(error);
      });
    },
    encode(id) {
      return encodeURIComponent(CryptoJS.AES.encrypt(String(id), "Secret Passphrase"));
    }
  },
  computed: {
    journals() {
      return this.$store.state.normalStore.journals;
    },
    clients() {
      return this.$store.state.normalStore.clients;
    },
    stories() {
      return this.$store.state.normalStore.stories;
    }
  }

};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <h1 class="navbar-brand-box">
          <router-link to="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="@/assets/images/logo-header.png" alt height="36" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-header.png" alt height="30" />
            </span>
          </router-link>

          <router-link to="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/logo-header-light.png" alt height="36" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-header-light.png" alt height="30" />
            </span>
          </router-link>
        </h1>

        <button id="toggle" type="button" class="btn btn-sm me-2 font-size-16 d-lg-none header-item"
          @click="toggleMenu">
          <i class="fa fa-fw fa-bars"></i>
        </button>
      </div>

      <div class="d-flex">
        <!-- App Search-->
        <!-- <form class="app-search d-none d-lg-block">
          <div class="position-relative">
            <input type="text" class="form-control" placeholder="Search..." v-model="searchText"   @input="search"/>
            <span class="bx bx-search-alt"></span>
          </div>
        </form> -->
        <b-dropdown class="d-inline-block ms-2" variant="black" menu-class="dropdown-menu-lg p-0 dropdown-menu-end"
          toggle-class="header-item noti-icon" right v-if="$store.state.authenticatedUser.userType === 'admin'">
          <template v-slot:button-content>
            <i class="mdi mdi-magnify"></i>
          </template>

          <form class="p-0">
            <div class="form-group m-0">
              <div class="input-group p-3">
                <input type="text" class="form-control" placeholder="Search ..." aria-label="Recipient's username"
                  v-model="searchText" @input="search" />
                <div class="input-group-append">
                  <button class="btn btn-theme" type="button">
                    <i class="mdi mdi-magnify"></i>
                  </button>
                </div>
              </div>
              <ul class="dropListSearch">
                <template v-if="searchText.length == 0">
                  <li class="text-center pb-3" v-if="searchText.length == 0">
                    No Result Found
                  </li>
                </template>
                <template v-else>
                  <li v-for="(item, index) in filteredItems" :key="index">
                    <router-link
                      :to="(item.arrType === 'Journal' ? '/single-journal/' : (item.arrType === 'Client' ? '/single-client/' : '/single-story/')) + encode(item.id)"
                      class="px-3 py-2">
                      <span class="searchResultDt">
                        {{ item.arrType === 'Journal' ? item.jrnlName : item.arrType === 'Client' ? item.firstName + ' '
                          + item.lastName : item.storyTitle }} <sup class="badge badge-soft-primary">{{ item.arrType ===
                          'Story' ? item.clientName : '' }}</sup>
                      </span>
                      <span class="catInrSpn">{{ item.arrType }}</span>
                    </router-link>
                  </li>
                </template>
              </ul>
            </div>
          </form>
        </b-dropdown>


        <!-- <b-dropdown menu-class="dropdown-menu-lg p-0 dropdown-menu-end" toggle-class="header-item noti-icon"
          variant="black">
          <template v-slot:button-content>
            <i class="bx bx-bell bx-tada"></i>
            <span class="badge bg-danger rounded-pill">

              0</span>
          </template>

          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">
                  Notifications
                </h6>
              </div>
              <div class="col-auto">
                <a href="#" class="small"></a>
              </div>
            </div>
          </div>
          <SimpleBar style="max-height: 230px">
            <a href="javascript: void(0);" class="text-reset notification-item">
              <div class="d-flex">
                <div class="avatar-xs me-3">
                  <span class="avatar-title bg-primary rounded-circle font-size-16">
                    <i class="bx bx-cart"></i>
                  </span>
                </div>
                <div class="flex-grow-1">
                  <h6 class="mt-0 mb-1">
                    Dummy Title
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      Lorem Ipsum dummy description
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      12.00
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </SimpleBar>
          <div class="p-2 border-top d-grid">
            <a class="btn btn-sm btn-link font-size-14 text-center" href="javascript:void(0)">
              <i class="mdi mdi-arrow-right-circle me-1"></i>
              <span key="t-view-more">
                View more</span>
            </a>
          </div>
        </b-dropdown> -->

        <b-dropdown right variant="black" toggle-class="header-item">
          <template v-slot:button-content>
            <img class="d-none rounded-circle header-profile-user" src="@/assets/images/users/avatar-1.jpg"
              alt="Header Avatar" />
            <span class="text-truncate userNameStyle" v-if="$store.state.authenticatedUser">{{
              $store.state.authenticatedUser.firstName }}
              {{ $store.state.authenticatedUser.lastName }}</span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </template>
          <!-- item-->
          <!-- <b-dropdown-item>
            <router-link to="/contacts/profile" v-slot="{ navigate }" custom>
              <span @click="navigate" @keypress.enter="navigate">
                <i class="bx bx-user font-size-16 align-middle me-1"></i>
                Profile
              </span>
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item class="d-block" href="javascript: void(0);">
            <span class="badge bg-success float-end">0</span>
            <i class="bx bx-wrench font-size-16 align-middle me-1"></i>
            Setting
          </b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider> -->

          <div v-if="$store.state.authenticatedUser.userType == 'admin'">
            <router-link to="/profile" class="dropdown-item text-info">
              <i class="bx bx-user font-size-16 align-middle me-1"></i>
              <!-- <i class="bx bx-power-off font-size-16 align-middle me-1 text-info"></i> -->
              Profile
            </router-link>
            <router-link to="/users" class="dropdown-item text-info">
              <i class="bx bx-group font-size-16 align-middle me-1"></i>
              Users
            </router-link>
            <router-link to="/email-template" class="dropdown-item text-info">
              <i class="mdi mdi-file-document-outline font-size-16 align-middle me-1"></i>
              <!-- <i class="bx bx-power-off font-size-16 align-middle me-1 text-info"></i> -->
              Email Templates
            </router-link>
            <router-link to="/default-task" class="dropdown-item text-success">
              <i class="mdi mdi-file-document-outline font-size-16 align-middle me-1 text-success"></i>
              Task Management
            </router-link>
            <!-- <router-link to="/add-extra-services" class="dropdown-item text-theme">
              <i class="mdi mdi-file-document-outline font-size-16 align-middle me-1 text-theme"></i>
              Extra Services
            </router-link> -->
            <router-link to="/pending-payments" class="dropdown-item text-theme">
              <i class="mdi mdi-file-document-outline font-size-16 align-middle me-1 text-theme"></i>
              Pending Payments
            </router-link>
            <router-link to="/all-orders" class="dropdown-item text-theme">
              <i class="mdi mdi-file-document-outline font-size-16 align-middle me-1 text-theme"></i>
              Orders
            </router-link>
            <router-link to="/services" class="dropdown-item text-theme">
              <i class="bx bx-package font-size-16 align-middle me-1 text-theme"></i>
              Services
            </router-link>
            <router-link to="/coupons" class="dropdown-item text-theme">
              <i class="mdi mdi-script-text-outline font-size-16 align-middle me-1 text-theme"></i>
              Coupons
            </router-link>
          </div>


          <!-- Client routes -->
          <div v-if="$store.state.authenticatedUser.userType == 'client'">
            <router-link to="/setting" class="dropdown-item text-theme">
              <i class="mdi mdi-pencil font-size-16 align-middle me-1 text-theme"></i>
              Edit Profile
            </router-link>
            <!-- <router-link to="/change-password" class="dropdown-item text-theme"><i
                class="mdi mdi-password font-size-16 align-middle me-1 text-theme"></i>Change Password</router-link> -->

            <router-link to="/pending-payment" class="dropdown-item text-theme">
              <i class="mdi mdi-file-document-outline font-size-16 align-middle me-1 text-theme"></i>
              Pending Payments
            </router-link>

            <router-link to="/orders" class="dropdown-item text-theme">
              <i class="bx bx-copy-alt font-size-16 align-middle me-1 text-theme"></i>
              Orders
            </router-link>

            <a class="dropdown-item text-theme " href="https://www.submititnow.com/contact" target="_blank">
              <i class="mdi mdi-contacts font-size-16 align-middle me-1 text-theme"></i>
              Contact Us
            </a>
          </div>
          <button @click="logout" class="dropdown-item text-danger">
            <i class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
            Logout
          </button>
        </b-dropdown>

      </div>
    </div>
  </header>
</template>
